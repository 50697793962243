.overview-page-container {

    .analytics-overview-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 20px;
        gap: 15px;
        flex-wrap: wrap;

        @media only screen and (max-width: 991px) {
            padding-bottom: 120px !important;
            max-width: 100%;
            
            .card {
                width: 100%;
            }
        }
        @media only screen and (min-width: 992px) {
            max-width: min(1120px, 100%);

            .table-card {
                width: 100%;
            }
        }
    }

    .mt-20 {
        margin-top: 20px;
    }

    
    .mt-0 {
        margin-top: 0px;
    }

    .swap-card {
        width: 500px;
        max-width: 100%;
    }
}